import { Injectable } from '@angular/core';
import { BusinessRole, Entity, SpaceRole, UserRole } from '../model/business-role.model';
import { ApiService } from './api.service';
import * as convertKeys from 'convert-keys';
import { firstValueFrom, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { RoleToDelete } from 'src/app/dialogs/submit-roles-dialog/submit-roles-dialog.component';

@Injectable()
export class BusinessRolesService {
  private businessRoles: BusinessRole[];
  private userRoles: UserRole[];
  public errorRoles: RoleToDelete[];
  public businessRoles$: Subject<BusinessRole[]> = new Subject<BusinessRole[]>();
  public userRoles$: BehaviorSubject<{ roles: UserRole[]; userId: string }> = new BehaviorSubject({
    roles: null,
    userId: null,
  });
  public spacesWithRoles$: BehaviorSubject<SpaceRole[]> = new BehaviorSubject([]);
  public errorRoles$: Subject<RoleToDelete[]> = new Subject<RoleToDelete[]>();
  public newSpacesWithRoles$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public updateRoleRequirements$: BehaviorSubject<[]> = new BehaviorSubject([]);
  public removedSpace$: BehaviorSubject<string> = new BehaviorSubject(null);
  public removedRole$: Subject<UserRole[]> = new Subject<UserRole[]>();
  public submittedRolesStatus$: BehaviorSubject<Map<string, string>> = new BehaviorSubject(new Map());
  public clearNewSpaces$: Subject<boolean> = new Subject<boolean>();
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loadingRoles = false;

  constructor(private apiService: ApiService) {
    this.userRoles = [];
    this.businessRoles;
    this.errorRoles = [];
  }

  async getSpaceRoles() {
    try {
      this.loadingRoles = true;
      const roles = await this.apiService.get('business-roles');
      this.businessRoles = convertKeys.toCamel<BusinessRole[]>(roles);
      this.businessRoles$.next(this.businessRoles);
      this.loadingRoles = false;
    } catch (err) {
      this.loadingRoles = false;
      console.log('Could not load roles.', err);
    }
  }

  async getUserRoles(userID: string) {
    try {
      this.loading$.next(true);
      // Initialize map
      const spacesWithRoles: SpaceRole[] = [];

      // Retrieve data from API
      const userRolesObject = await this.apiService.get(`users/${userID}/roles`);

      this.userRoles = [];

      // Create business roles array with model structure
      if (!this.businessRoles) {
        if (!this.loadingRoles) {
          await this.getSpaceRoles();
        } else {
          await firstValueFrom(this.businessRoles$);
        }
      }

      // Create user roles array with model structure
      if (userRolesObject) {
        this.userRoles = convertKeys.toCamel<UserRole[]>(userRolesObject);
      }

      // Retrieve user roles display internationalized labels
      this.userRoles.forEach((role) => {
        role.selected = false;
        role.displayLabel =
          this.businessRoles.find((businessRole) => businessRole.name === role.businessRole)?.displayLabel ||
          role.businessRole;
      });

      // Retrieve unique spaces ID given user roles
      const uniqueSpacesIDs = this.getUniqueSpacesId(this.userRoles);

      // Finally, build a list of roles associated to this space ID
      uniqueSpacesIDs.forEach((id) => {
        const rolesList: UserRole[] = [];
        this.userRoles.forEach((role) => {
          if (role.entityIds.some((entity) => entity.id === id)) {
            rolesList.push(role);
          }
        });
        spacesWithRoles.push({ ...this.getSpace(id), activeRoles: rolesList, newRoles: [] });
      });

      // And send it to observable
      this.userRoles$.next({ roles: this.userRoles, userId: userID });
      this.spacesWithRoles$.next(spacesWithRoles);
      return spacesWithRoles;
    } catch (error) {
      throw new Error(`something went wrong: ${error}`);
    } finally {
      this.loading$.next(false);
    }
  }

  async submitRoles(userId: string, entityId: string, role: string, entity_type: string, contact_id: string) {
    return await this.apiService.put(`users/${userId}/entities/${entityId}/roles/${role}`, {});
  }

  async deleteRole(userId: string, entityId: string, role: string, entity_type: string, contact_id: string) {
    return await this.apiService.delete(`users/${userId}/entities/${entityId}/roles/${role}`);
  }

  async removedAllRoles(roles: UserRole[]) {
    await this.removedRole$.next(roles);
  }

  errorRolesInclude(toFind: string, entity: string): boolean {
    const found = this.errorRoles.find((role) => role.role.businessRole === toFind && role.entity.id === entity);
    return found !== undefined;
  }

  async updateErrorRoles(roles: RoleToDelete[]) {
    this.errorRoles = roles;
    this.errorRoles$.next(this.errorRoles);
  }

  /**
   * Build an array of unique entities (avoiding duplicates)
   * @param id
   */
  private getUniqueSpacesId(userRoles: UserRole[]): string[] {
    const entitiesIDs = [];
    userRoles.map((rol) =>
      rol.entityIds.forEach((entity) => {
        entitiesIDs.push(entity.id);
      }),
    );

    return [...new Set(entitiesIDs)];
  }

  /**
   * Retrieve the entity which belongs to the ID given
   * @param id
   */
  private getSpace(id: string): Entity {
    let entity: Entity = null;
    this.userRoles.forEach((role) => {
      role.entityIds.forEach((ent) => {
        if (ent.id === id) {
          entity = { ...ent, displayLabel: ent.displayLabels[Object.keys(ent.displayLabels)[0]] };
        }
      });
    });

    return entity;
  }
}
