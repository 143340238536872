import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteRolesDialogComponent } from 'src/app/dialogs/delete_roles/delete-roles-dialog.component';
import {
  ChangeDetectorRef,
  Component,
  Input,
  Output,
  OnInit,
  QueryList,
  ViewChildren,
  EventEmitter,
} from '@angular/core';
import { SpaceRole, UserRole } from 'src/app/shared/model/business-role.model';
import { BusinessRolesService } from '../../shared/services/business-roles.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ActiveLocationComponent } from './active-location/active-location.component';
import { UserService } from 'src/app/shared/services/user.service';
import { UserStatusService } from '../../shared/services/user-status.service';
import {
  RoleToDelete,
  SubmitRolesDialogComponent,
  SubmitRolesDialogData,
} from 'src/app/dialogs/submit-roles-dialog/submit-roles-dialog.component';

@Component({
  selector: 'app-active-location-roles',
  templateUrl: './active-location-roles.component.html',
  styleUrls: ['./active-location-roles.component.scss'],
})
export class ActiveLocationRolesComponent implements OnInit {
  @Input() mode: string;
  @Input() changed: boolean;
  @Input() hasEcrm: boolean;
  @ViewChildren(ActiveLocationComponent) activeLocations: QueryList<ActiveLocationComponent>;
  @Output() onRoleRemoval = new EventEmitter<string>();
  @Output() onSiteRemoval = new EventEmitter<number[]>();
  @Output() onRoleUpdate = new EventEmitter<void>();
  @Output() unRemoveRoleEvent = new EventEmitter<void>();

  EDIT = 'edit';

  userId: string;
  userStatus: string;
  contactId: string;
  subscriptions: Subscription[] = [];
  ecrmSync = false;

  loadingLocationsAndRoles: boolean;
  spacesWithRoles: SpaceRole[];
  newSpacesWithRoles: SpaceRole[] = [];
  showDelete = false;

  errorRoleArray: RoleToDelete[] = [];

  submitRef: MatDialogRef<SubmitRolesDialogComponent>;

  constructor(
    private route: ActivatedRoute,
    private businessRolesService: BusinessRolesService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private userService: UserService,
    private userStatusService: UserStatusService,
  ) {
    const spacesAndRolesSub = this.businessRolesService.spacesWithRoles$.subscribe((spacesWithRoles) => {
      this.loadingLocationsAndRoles = false;
      this.spacesWithRoles = spacesWithRoles;
      for (const role of this.errorRoleArray) {
        const location = this.activeLocations.find((location) => location.spaceWithRoles.id === role.entity.id);
        if (location) {
          const chip = location.roleChips.find((chip) => chip.role.businessRole === role.role.businessRole);
          if (chip) {
            chip.updateStatus('FAILED');
          }
        }
      }
    });
    const statusSub = this.userStatusService.userStatus$.subscribe((status) => {
      this.userStatus = status;
    });
    this.subscriptions.push(spacesAndRolesSub, statusSub);
  }

  ngOnInit() {
    const routeSub = this.route.params.subscribe(async (params) => {
      if (params.id) {
        this.userId = params.id;
        this.loadingLocationsAndRoles = true;
        this.businessRolesService.getUserRoles(this.userId);
        const user = await this.userService.getUser(this.userId);
        this.contactId = user.contact_id;
        this.ecrmSync = user.sync_to_ecrm;
        this.businessRolesService.newSpacesWithRoles$.next([]);
      }
    });
    const clearNewSpacesSub = this.businessRolesService.clearNewSpaces$.subscribe((clear) => {
      if (clear) {
        this.newSpacesWithRoles = [];
      }
    });

    const newSpacesWithRolesSub = this.businessRolesService.newSpacesWithRoles$.subscribe((newSpaceWithRoles) => {
      if (newSpaceWithRoles && newSpaceWithRoles.length) {
        const newSpaces = newSpaceWithRoles.filter((newSpace) => {
          return (
            !this.spacesWithRoles?.find((activeSpace) => activeSpace.id === newSpace.id) &&
            !this.newSpacesWithRoles?.find((activeSpace) => activeSpace.id === newSpace.id)
          );
        });
        if (newSpaces) {
          this.newSpacesWithRoles = [...new Set([...this.newSpacesWithRoles, ...newSpaces])];
          this.cdRef.detectChanges();
        }
      }
    });

    if (this.mode !== 'edit') {
      this.newSpacesWithRoles = [];
    }

    this.subscriptions.push(...[routeSub, newSpacesWithRolesSub, clearNewSpacesSub]);
  }

  removeLocation(spaceId): void {
    const location = this.spacesWithRoles.find((spaceWithRoles) => spaceWithRoles.id === spaceId);
    if (location) {
      this.onSiteRemoval.emit([location.newRoles.length, location.activeRoles.length]);
      // this.openDeleteEntityDialog(location);
    }
  }

  openDeleteEntityDialog(location: SpaceRole) {
    return this.dialog.open(DeleteRolesDialogComponent, {
      width: '400px',
      data: {
        roles: [...location.activeRoles],
        entity: location,
        deleteEntityAccess: true,
        userId: this.userId,
        sync: this.ecrmSync,
        allSpaceRoles: this.spacesWithRoles,
        status: this.userStatus,
      },
      disableClose: true,
    });
  }

  removeNewLocation(spaceId): void {
    const location = this.newSpacesWithRoles.find((spaceWithRoles) => spaceWithRoles.id === spaceId);
    if (location) {
      this.onSiteRemoval.emit([location.newRoles.length, 0]);
      this.businessRolesService.removedSpace$.next(spaceId);
      const index = this.newSpacesWithRoles.indexOf(location);
      if (index >= 0) {
        this.newSpacesWithRoles.splice(index, 1);
        this.cdRef.detectChanges();
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  onRoleRemoved($event) {
    this.onRoleRemoval.emit($event);
  }

  resetRoles() {
    this.activeLocations.forEach((location) => {
      location.resetNewRoles();
    });
  }

  //Opens a dialog to confirm the role changes.
  openSubmitRolesDialog() {
    //Find changes to be submitted.
    let totalAddedRoles = 0;
    const rolesToDelete: RoleToDelete[] = [];
    this.activeLocations.forEach((location) => {
      totalAddedRoles += location.spaceWithRoles.newRoles.length;
      rolesToDelete.push(
        ...location.spaceWithRoles.rolesToDelete.map((role) => {
          return { role: role, entity: location.spaceWithRoles };
        }),
      );
      location.roleChips.forEach((chip) => {
        const rol: any = chip.role;
        if (
          chip.toDelete &&
          !rolesToDelete.find(
            (role) => role.role.businessRole === rol.businessRole && role.entity.id === location.spaceWithRoles.id,
          )
        ) {
          rolesToDelete.push({ role: rol, entity: location.spaceWithRoles });
        }
      });
    });
    const data: SubmitRolesDialogData = {
      totalAddedRoles: totalAddedRoles,
      rolesToDelete: rolesToDelete,
      userId: this.userId,
      contactId: this.contactId,
      status: this.userStatus,
    };

    this.submitRef = this.dialog.open(SubmitRolesDialogComponent, {
      width: '400px',
      data: data,
      disableClose: true,
    });
    //Check for new Comm role
    const hasNewCommRole = this.activeLocations.some((location) =>
      location.spaceWithRoles.newRoles.some((role: any) => role.name === 'COMMUNITY_USER'),
    );
    //If the Community role is added and the user has no ECRM, show a warning.
    if (hasNewCommRole && !this.hasEcrm) {
      // Set a warning message in the submitRef component instance.
      this.submitRef.componentInstance.warning = 'user.dialog.submit_roles.ecrm_warning';
    }

    //OnRoleUpdate event is emitted when the update is confirmed.
    this.submitRef.componentInstance.onRoleUpdate.subscribe(() => {
      this.onRoleUpdate.emit();
    });

    this.submitRef.componentInstance.resetRemoved.subscribe(() => {
      this.resetRoles();
    });

    this.submitRef.componentInstance.errorRolesEvent.subscribe((errorRoles) => {
      this.errorRoles(errorRoles);
    });
  }

  //Send error message to dialog if role update failed.
  sendErrorToDialog() {
    this.submitRef.componentInstance.errors += this.errorRoleArray.length;
    this.submitRef.componentInstance.setEndState('add', 'ERROR');
  }

  //Send success message to dialog if role update succeeded.
  sendSuccessToDialog() {
    this.submitRef.componentInstance.setEndState('add', 'SUCCESS');
  }

  sendUpdatingToDialog() {
    this.submitRef.componentInstance.setStateUpdating('add');
  }

  sendProgressToDialog(sumbitted: number) {
    this.submitRef.componentInstance.added = sumbitted;
  }

  unRemoveRole($event) {
    this.unRemoveRoleEvent.emit($event);
  }

  errorRoles(errorRoles: RoleToDelete[]) {
    this.errorRoleArray = errorRoles;
    this.businessRolesService.updateErrorRoles(errorRoles);
    this.onSiteRemoval.emit([0, 0]);
  }

  addNewRoles(roles: RoleToDelete[]) {
    for (const role of roles) {
      const location = this.activeLocations.find((location) => location.spaceWithRoles.id === role.entity.id);
      if (location) {
        location.spaceWithRoles.newRoles.push(role.role);
      } else {
        role.entity.newRoles = [...new Set([...role.entity.newRoles, role.role])];
        this.newSpacesWithRoles = [...new Set([...this.newSpacesWithRoles, role.entity])];
      }
    }
  }
}
