<div mat-dialog-content>
    <div class="dialog-spinner">
        <div class="spinner-container">
            <mat-spinner diameter="24"></mat-spinner>
        </div>
        <ng-container *ngIf="dialogState === stage.updating_user">
            <span data-automation="dialogTextUpdating">{{ 'user.dialog.update.updating_user' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="dialogState === stage.updating_roles">
            <span data-automation="dialogTextUpdating">{{ 'user.dialog.update.updating_roles' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="dialogState === stage.updating_subs">
            <span style="white-space: pre-line;" data-automation="dialogTextUpdating">{{
                'user.dialog.update.updating_subscriptions' | translate
            }} {{ subscriptionProgress }}</span>
        </ng-container>
    </div>
</div>
