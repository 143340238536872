import { Component, ViewChild, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { SubscriptionTable } from '../model/subscription-table.model';
import { SubscriptionTableService } from '../services/subscription-table.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

enum MODE {
  EDIT = 'edit',
  VIEW = 'view',
}

export interface SubscriptionObject {
  subscriptionId: string;
  entityId: string;
  entityType: string;
  name: string;
  sms: boolean;
  voice: boolean;
  email: boolean;
  offer: boolean;
  performance: boolean;
}

@Component({
  selector: 'app-subscription-table',
  templateUrl: './subscription-table.component.html',
  styleUrls: ['./subscription-table.component.scss', '../shared.styles.scss'],
})
export class SubscriptionTableComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['site', 'text', 'voice', 'email', 'offer', 'performance'];
  @Input() mode: string;
  oldData: SubscriptionObject[] = [];
  @Input() userId: string;
  public dataSource: MatTableDataSource<SubscriptionTable> = new MatTableDataSource();
  private paginator: MatPaginator;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;

    if (this.paginator) {
      this.applyFilter('');
    }
  }
  totalRecords = 0;
  pageSize = 10;
  loading = false;

  destroySubject$: Subject<void> = new Subject();
  searchText = '';

  @Input() hasAnyPhoneField: boolean;
  @Input() hasAnyMobilePhoneField: boolean;
  @Input() hasEmailField: boolean;
  @Output() addSubscriptionEvent = new EventEmitter<string>();
  @Output() delSubscriptionEvent = new EventEmitter<string>();

  EDIT = 'edit';
  subscriptions = [];
  subs: any[] = [];

  @ViewChild('matRefDispatch') matRefDispatch: MatSelect;
  @ViewChild('matRefText') matRefText: MatSelect;
  @ViewChild('matRefVoice') matRefVoice: MatSelect;
  @ViewChild('matRefEmail') matRefEmail: MatSelect;
  @ViewChild('matRefOffer') matRefOffer: MatSelect;
  @ViewChild('matRefPerformance') matRefPerformance: MatSelect;

  iconTooltip: MatIconRegistry;

  allComplete = false;
  bulkSubActionsLabelAssign = '';
  bulkSubActionsLabelUnassign = '';
  bulkActionTypeLabelAssign = '';
  bulkActionTypeLabelUnassign = '';
  bulkActionTextLabel = '';
  bulkActionVoiceLabel = '';
  bulkActionEmailLabel = '';
  bulkActionOfferLabel = '';
  bulkActionPerformanceLabel = '';
  bulkSubActions = [];
  bulkSubActionsText = [];
  bulkSubActionsVoice = [];
  bulkSubActionsEmail = [];
  bulkSubActionsOffer = [];
  bulkSubActionsPerformance = [];

  updateAllComplete() {
    this.allComplete = this.subs != null && this.subs.every((t) => t.completed);
  }

  someComplete(): boolean {
    if (this.subs == null) {
      return false;
    }
    return this.subs.filter((t) => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.subs == null) {
      return;
    }
    this.subs.forEach((t) => (t.completed = completed));
    if (this.subs.filter((t) => t.completed).length == 0) {
      this.clear();
    }
  }

  toggleSiteCompleted(site) {
    console.log(site);
  }

  get isEditMode() {
    return this.mode === MODE.EDIT;
  }

  get isViewMode() {
    return this.mode === MODE.VIEW;
  }

  constructor(
    private translateService: TranslateService,
    private subscriptionTableService: SubscriptionTableService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.iconTooltip = iconRegistry.addSvgIcon(
      'general-info',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/general-info.svg'),
    );

    this.bulkSubActionsLabelAssign = this.translateService.instant('user.table.bulk_sub_actions_label_assign');
    this.bulkSubActionsLabelUnassign = this.translateService.instant('user.table.bulk_sub_actions_label_unassign');
    this.bulkActionTypeLabelAssign = this.translateService.instant('user.table.bulk_action_type_label_assign');
    this.bulkActionTypeLabelUnassign = this.translateService.instant('user.table.bulk_action_type_label_unassign');

    this.bulkActionTextLabel = this.translateService.instant('user.table.bulk_action_type_text');
    this.bulkActionVoiceLabel = this.translateService.instant('user.table.bulk_action_type_voice');
    this.bulkActionEmailLabel = this.translateService.instant('user.table.bulk_action_type_email');
    this.bulkActionOfferLabel = this.translateService.instant('user.table.bulk_action_type_offer');
    this.bulkActionPerformanceLabel = this.translateService.instant('user.table.bulk_action_type_performance');

    this.bulkSubActions = [
      { value: 'bulk-action-assign-all', viewValue: this.bulkSubActionsLabelAssign },
      { value: 'bulk-action-unassign-all', viewValue: this.bulkSubActionsLabelUnassign },
    ];

    this.bulkSubActionsText = [
      { value: 'bulk-action-text-assign', viewValue: this.bulkActionTypeLabelAssign },
      { value: 'bulk-action-text-unassign', viewValue: this.bulkActionTypeLabelUnassign },
    ];

    this.bulkSubActionsVoice = [
      { value: 'bulk-action-voice-assign', viewValue: this.bulkActionTypeLabelAssign },
      { value: 'bulk-action-voice-unassign', viewValue: this.bulkActionTypeLabelUnassign },
    ];

    this.bulkSubActionsEmail = [
      { value: 'bulk-action-email-assign', viewValue: this.bulkActionTypeLabelAssign },
      { value: 'bulk-action-email-unassign', viewValue: this.bulkActionTypeLabelUnassign },
    ];

    this.bulkSubActionsOffer = [
      { value: 'bulk-action-offer-assign', viewValue: this.bulkActionTypeLabelAssign },
      { value: 'bulk-action-offer-unassign', viewValue: this.bulkActionTypeLabelUnassign },
    ];

    this.bulkSubActionsPerformance = [
      { value: 'bulk-action-performance-assign', viewValue: this.bulkActionTypeLabelAssign },
      { value: 'bulk-action-performance-unassign', viewValue: this.bulkActionTypeLabelUnassign },
    ];
  }

  ngOnInit(): void {
    const loadingSubs = this.subscriptionTableService.loadingData$.subscribe((loading) => (this.loading = loading));

    const subscriptionTableData = this.subscriptionTableService.subscriptionTableData$.subscribe((tableData) => {
      this.setupTableDataSource(tableData);
    });

    this.subscriptions.push(...[loadingSubs, subscriptionTableData]);
  }

  private setupTableDataSource(sortedSubscriptionTable: SubscriptionTable[]): void {
    let filteredSubscriptionTable;
    if (this.isViewMode) {
      filteredSubscriptionTable = sortedSubscriptionTable.filter(
        (sub) =>
          sub.emailSubscriptionId ||
          sub.voiceSubscriptionId ||
          sub.smsSubscriptionId ||
          sub.offerSubscriptionId ||
          sub.performanceSubscriptionId,
      );
    } else if (this.isEditMode) {
      filteredSubscriptionTable = sortedSubscriptionTable;
    }
    this.dataSource = new MatTableDataSource<SubscriptionTable>(filteredSubscriptionTable);
    this.totalRecords = this.dataSource.data.length;
    this.subs = this.dataSource.data;
    this.dataSource.paginator = this.paginator;

    let sentEmail = false;
    let sentVoice = false;
    let sentSms = false;
    let sentOffer = false;
    let sentPerformance = false;
    for (
      let i = 0;
      i < this.totalRecords && !(sentEmail && sentSms && sentVoice && sentOffer && sentPerformance);
      i++
    ) {
      if (filteredSubscriptionTable[i].email && !sentEmail) {
        sentEmail = true;
        this.addSubscriptionEvent.emit('email');
      }
      if (filteredSubscriptionTable[i].voice && !sentVoice) {
        sentVoice = true;
        this.addSubscriptionEvent.emit('voice');
      }
      if (filteredSubscriptionTable[i].sms && !sentSms) {
        sentSms = true;
        this.addSubscriptionEvent.emit('sms');
      }
      if (filteredSubscriptionTable[i].offer && !sentOffer) {
        sentOffer = true;
        this.addSubscriptionEvent.emit('offer');
      }
      if (filteredSubscriptionTable[i].performance && !sentPerformance) {
        sentPerformance = true;
        this.addSubscriptionEvent.emit('performance');
      }
    }
    this.oldData = filteredSubscriptionTable.map((sub) => {
      return { ...sub };
    });
  }

  applyFilter(search: string): void {
    this.dataSource.filter = search.trim().toLowerCase();
  }

  changeBulkSubAction(type: string, action: any) {
    this.clear();
    this.subs
      .filter((sub) => sub.completed)
      .forEach((sub) => {
        if (action.value?.includes('assign')) {
          sub[type] = true;
        }
        if (action.value?.includes('unassign')) {
          sub[type] = false;
        }
      });
  }

  onPaginateChange(event: any): void {
    if (event.pageIndex == Math.ceil(this.totalRecords / this.pageSize) - 1) {
      const data = this.dataSource.data;
      this.dataSource = new MatTableDataSource(data);
      this.totalRecords = this.dataSource.data.length;
      this.dataSource.paginator = this.paginator;
    }
  }

  changeBulkSubActions(action: any) {
    this.changeBulkSubAction('sms', action);
    this.changeBulkSubAction('voice', action);
    this.changeBulkSubAction('email', action);
  }

  isSelectEnabled() {
    return this.subs.filter((sub) => sub.completed).length > 0;
  }

  clear() {
    this.matRefDispatch.options.forEach((data: MatOption) => data.deselect());
    this.matRefText.options.forEach((data: MatOption) => data.deselect());
    this.matRefVoice.options.forEach((data: MatOption) => data.deselect());
    this.matRefEmail.options.forEach((data: MatOption) => data.deselect());
  }

  subscriptionChanges() {
    const changes = [];
    this.dataSource.data.forEach((sub: any) => {
      const old: any = this.oldData.find((older: any) => {
        return older.tag === sub.tag;
      });
      if (old) {
        if (
          old.sms !== sub.sms ||
          old.voice !== sub.voice ||
          old.email !== sub.email ||
          old.offer !== sub.offer ||
          old.performance !== sub.performance
        ) {
          const newSub = {
            emailSubscriptionId: sub.emailSubscriptionId || old.emailSubscriptionId,
            voiceSubscriptionId: sub.voiceSubscriptionId || old.voiceSubscriptionId,
            smsSubscriptionId: sub.smsSubscriptionId || old.smsSubscriptionId,
            offerSubscriptionId: sub.offerSubscriptionId || old.offerSubscriptionId,
            performanceSubscriptionId: sub.performanceSubscriptionId || old.performanceSubscriptionId,
            entityId: sub.entityId || old.tag,
            entityType: old.entityType || 'SITE',
          };
          if (old.sms !== sub.sms && old.sms) {
            newSub['sms'] = 'del';
          } else if (old.sms !== sub.sms && sub.sms) {
            newSub['sms'] = 'add';
          }
          if (old.voice !== sub.voice && old.voice) {
            newSub['voice'] = 'del';
          } else if (old.voice !== sub.voice && sub.voice) {
            newSub['voice'] = 'add';
          }
          if (old.email !== sub.email && old.email) {
            newSub['email'] = 'del';
          } else if (old.email !== sub.email && sub.email) {
            newSub['email'] = 'add';
          }
          if (old.offer !== sub.offer && old.offer) {
            newSub['offer'] = 'del';
          }
          if (old.offer !== sub.offer && sub.offer) {
            newSub['offer'] = 'add';
          }
          if (old.performance !== sub.performance && old.performance) {
            newSub['performance'] = 'del';
          }
          if (old.performance !== sub.performance && sub.performance) {
            newSub['performance'] = 'add';
          }

          changes.push(newSub);
        }
      }
    });
    return changes;
  }

  changeSub(type: string, change) {
    if (change.checked) {
      this.addSubscriptionEvent.emit(type);
    } else {
      if (
        this.dataSource.data.filter((sub) => {
          return sub[type];
        }).length === 0
      ) {
        this.delSubscriptionEvent.emit(type);
      }
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
