import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';
import { PermissionService } from './permissions.service';
import { Resource } from '../model/permission.model';
import { filter, firstValueFrom } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  readonly forbiddenPath = '/forbidden/index.html';
  private errorType: string;

  constructor(private userService: UserService, private permissionService: PermissionService, private router: Router) {
    this.errorType = 'unauthorized';
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const { usersManagement, profile, path } = route.data;
    const { userId } = await this.userService.getLoggedInUser();
    await firstValueFrom(this.userService.loggedInUser$.pipe(filter(Boolean)));
    await firstValueFrom(this.permissionService.permissions$.pipe(filter(Boolean)));

    if (this.permissionService.canSeeOrgUsers()) {
      if (this.permissionService.hasPermission(usersManagement, Resource.usersManagement)) {
        return true;
      }
      const paramsId = route.params['id'] || userId;
      this.router.navigate([`/details/${paramsId}/view`]);
    } else if (this.permissionService.canSeeOwnProfile()) {
      if (this.permissionService.hasPermission(profile, Resource.profile)) {
        if (route.params['id'] && userId === route.params['id']) {
          return true;
        }
      }
      this.router.navigate([`/details/${userId}/view`]);
    } else {
      document.location.href = document.location.origin + this.forbiddenPath + '?type=' + this.errorType;
      return false;
    }
  }
}
