import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { User, UserDefaults, UserPost, UserPatch } from '../model/user.model';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UserService {
  redirectUrl: string;
  readonly user$: BehaviorSubject<User> = new BehaviorSubject<User>({ ...UserDefaults });
  readonly users$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(null);
  readonly orgId$ = new BehaviorSubject<string>(null);
  readonly selectedUserId$ = new BehaviorSubject<string>(null);
  loggedInUser$ = new BehaviorSubject(<User>null);
  savingUser$ = new BehaviorSubject(false);

  constructor(private apiService: ApiService) {
    this.getLoggedInUser();
  }

  /**
   * Gets a user from the backend and stores it in cache for reuse
   *
   * @returns {Promise<User>}
   */
  async getUser(id: string): Promise<User> {
    const userData = await this.apiService.get(`users/${id}`);
    const formattedUserData = this.buildUserData(userData);

    this.user$.next(formattedUserData);
    return formattedUserData;
  }

  async getLoggedInUser() {
    let formattedUserData = this.loggedInUser$.getValue();
    if (!formattedUserData) {
      const userData = await this.apiService.get(`user/details`);
      formattedUserData = this.buildUserData(userData);
      this.loggedInUser$.next(formattedUserData);
    }
    return formattedUserData;
  }

  setSelectedUser(id) {
    this.selectedUserId$.next(id);
  }

  /**
   * Get a list of users that belong to a given organization
   * @param orgId Organization ID
   */
  async getUsersByOrg(orgId: string) {
    try {
      const users = await this.apiService.get(`organizations/${orgId}/users`);
      this.users$.next(convertKeys.toCamel(users));
      this.orgId$.next(orgId);
    } catch (err) {
      console.log(`Could not load Users for Org: ${orgId}`, err);
      this.users$.next([]);
      throw err;
    }
  }

  /**
   * Get user list back again
   */
  async refetchUsers() {
    if (this.orgId$.value) {
      await this.getUsersByOrg(this.orgId$.value);
    }
  }

  /**
   * Create a new user
   * @param user
   */
  async createUser(user: UserPost) {
    try {
      return await this.apiService.post('users', { ...user });
    } catch (err) {
      console.log(`Could not Create User`, err);
      throw err;
    }
  }

  /**
   * Update an existing user
   * @param userID
   * @param user
   */
  async updateUser(userID: string, user: UserPost) {
    try {
      return await this.apiService.put(`users/${userID}`, { ...user });
    } catch (err) {
      console.log(`Could not Update User`, err);
      throw err;
    }
  }

  /**
   * Update an existing ECRM username
   * @param userID
   * @param federatedApplications
   */
  async updateUserECRM(userID: string, federatedApplications: UserPatch) {
    try {
      return await this.apiService.patch(`users/${userID}`, { ...federatedApplications });
    } catch (err) {
      console.log('Could not update ECRM username', err);
      throw err;
    }
  }

  filterAddress(address) {
    const { state_province = '', address_1 = '', country = '', city = '', postal_code } = address;
    return (
      state_province.trim().length > 0 &&
      address_1.trim().length > 0 &&
      country.trim().length > 0 &&
      city.trim().length > 0 &&
      postal_code
    );
  }

  filterPhones(phone) {
    const { phone_number = '', country_code } = phone;
    return (phone_number as unknown as string).length > 0 && country_code && country_code > 0;
  }

  buildUserData(userData: any) {
    const {
      brand,
      username,
      title,
      user_status: { status },
      contact_id,
      flex_contact_id,
      sync_to_ecrm,
      last_sync_date,
    } = userData;

    const {
      profile: {
        contact_details: { addresses = [], emails = [], twentyfour_seven_contact, primary_contact },
      },
      user_type: userType,
    } = userData;
    const {
      profile: {
        contact_details: { phones = [] },
      },
    } = userData;
    const { user_class: userClass, country: countryCode, user_id: userId } = userData;
    const { display_label: organization_name, organization_id: organization_id } =
      userData.profile.organizations.filter((org) => org.primary)[0];
    const {
      preferences: {
        default_locale: locale,
        default_unit_system: unit_system,
        default_page: home_application,
        default_time_zone: timezone,
      },
    } = userData;
    const {
      email = (emails.find((email) => email.primary) || { email_address: '' }).email_address,
      phone_number = (phones.find((phone) => phone.primary) || { phone_number: '' }).phone_number,
    } = userData;

    const { username: ecrm_id = undefined } =
      (userData.federated_applications || []).find((app) => {
        return app.application_name === 'ECRM_SSO';
      }) || {};

    const {
      first_names: first_names = userData.first_names,
      last_names: last_names = userData.last_names,
      full_name: fullName = username,
    } = userData.profile.names || {};

    phones.sort((a, b) => b.primary - a.primary);

    const user = {
      first_names,
      last_names,
      fullName,
      title,
      userType,
      userClass,
      email,
      phone_number,
      phones,
      emails,
      countryCode,
      username,
      userId,
      brand,
      timezone,
      locale,
      addresses,
      status,
      unit_system,
      home_application,
      ecrm_id,
      contact_id,
      flex_contact_id,
      organization_name,
      organization_id,
      twentyfour_seven_contact,
      primary_contact,
      sync_to_ecrm,
      last_sync_date,
    };

    return user;
  }

  async deleteUserContact(flex_contact_id: string) {
    try {
      return await this.apiService.delete(`contacts/${flex_contact_id}`);
    } catch (err) {
      console.log('Could not delete user contact', err);
      throw err;
    }
  }
}
