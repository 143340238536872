import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResourceService } from '../../services/resource.service';
import { Permission, PermissionType, Resource } from '../model/permission.model';

const API_ENDPOINT = 'user/permissions';

@Injectable()
export class PermissionService {
  readonly permissions$ = new BehaviorSubject<PermissionType>(null);

  constructor(private resourceService: ResourceService<PermissionType>) {
    this.getPermissions$();
  }

  getPermissions$(): Observable<PermissionType> {
    const observingPermissions = this.resourceService.get(API_ENDPOINT);

    observingPermissions.subscribe((permissions) => this.permissions$.next(permissions));
    return observingPermissions;
  }

  hasPermission(requiredPermissions: Permission[], resource = Resource.usersManagement): boolean {
    const permissions = this.permissions$.getValue();
    if (!permissions) {
      return false;
    }
    const appPermissions = permissions[resource] ?? [];

    if (!appPermissions.length || !requiredPermissions.length) {
      return false;
    }

    return requiredPermissions.every((permission) => appPermissions.includes(permission));
  }

  canSubmitRoles() {
    return this.hasPermission([Permission.update], Resource.usersManagement);
  }

  canDeleteRoles() {
    return this.hasPermission([Permission.delete], Resource.usersManagement);
  }

  canUpdate() {
    return this.hasPermission([Permission.update], Resource.usersManagement);
  }

  canUpdateOwnProfile() {
    return this.hasPermission([Permission.update], Resource.profile);
  }

  canSeeOwnProfile() {
    return this.hasPermission([Permission.read], Resource.profile);
  }

  canSeeOrgUsers() {
    return this.hasPermission([Permission.read], Resource.usersManagement);
  }
}
