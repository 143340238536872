import { Injectable } from '@angular/core';
import { Space } from 'ngx-global-nav';
import { BehaviorSubject } from 'rxjs';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { AtlasPagesService } from 'atlas-pages-loader';
import { CookieService } from './cookie.service';
import { HttpClient } from '@angular/common/http';
import { SiteItem } from '../model/site.model';

const SPACES_HIERARCHY = ['organization', 'spacegroup', 'site', 'meter'];
const CONTEXT_HIERARCHY = ['organization'];

@Injectable()
export class SpaceService {
  hierarchyService: AtlasPagesService;

  hierarchy$: BehaviorSubject<Space[]>;
  readonly spaces$ = new BehaviorSubject<Space[]>([]);
  readonly sites$ = new BehaviorSubject<Space[]>([]);
  readonly contextList$ = new BehaviorSubject<Space[]>([]);
  readonly spaceHierarchies$ = new BehaviorSubject<any[]>([]);
  readonly flattenedMapOfTypes$ = new BehaviorSubject<Map<string, any>>(new Map());
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadingSpaces = false;

  constructor(private apiService: ApiService, private cookieService: CookieService, private httpClient: HttpClient) {
    this.sites$ = new BehaviorSubject<Space[]>([]);

    this.hierarchyService = new AtlasPagesService(
      this.cookieService.getMarketsMock(),
      this.cookieService.getEnocSession(),
      this.httpClient,
    );
  }

  async getSites(orgId: string) {
    try {
      const sites = await this.apiService.get(`organizations/${orgId}/sites`);
      this.sites$.next(convertKeys.toCamel(sites));
    } catch (err) {
      console.log('Could not load sites.', err);
    }
  }

  async getHierarchy() {
    try {
      const hierarchy = await this.apiService.get(`hierarchy`);
      this.hierarchy$.next(convertKeys.toCamel(hierarchy));
    } catch (err) {
      console.log('Could not load hierarchy.', err);
    }
  }

  async getSpecificHierarchies(hierachy: string[]) {
    try {
      const response = await this.hierarchyService.getSpecificHierarchies(hierachy);
      this.spaceHierarchies$.next(response);
      return response;
    } catch (error) {
      throw new Error(`something went wrong: ${error}`);
    }
  }

  // private cachedFlattenedMap: Map<string, any> | null = null;

  async getFlattenedMapOfTypes(hierarchy: string[], type: string, id: string): Promise<Map<string, any>> {
    /*if (this.cachedFlattenedMap) {
      return this.cachedFlattenedMap;
    }*/

    try {
      this.loading$.next(true);
      const response: any = await this.hierarchyService.getFlattenedMapOfTypes(
        hierarchy,
        { id: id, dr_type: type },
        true,
      );

      if (response) {
        // this.cachedFlattenedMap = response;
        this.flattenedMapOfTypes$.next(response);
      }

      return response;
    } catch (error) {
      throw new Error(`Something went wrong: ${error}`);
    } finally {
      this.loading$.next(false);
    }
  }

  async getOrgHierarchy(orgId?: string) {
    this.loadingSpaces = true;
    let allSpaces;
    if (orgId) {
      allSpaces = await this.hierarchyService.getSpecificHierarchies(SPACES_HIERARCHY, {
        id: orgId,
        dr_type: 'ORGANIZATION',
      });
    } else {
      allSpaces = await this.hierarchyService.getSpecificHierarchies(SPACES_HIERARCHY);
    }

    this.spaces$.next(convertKeys.toCamel(allSpaces));
    this.loadingSpaces = false;
  }

  async getHierarchyBySpaceGroupId(spaceGroupId: string) {
    this.loadingSpaces = true;
    const allSpaces = await this.apiService.get(`hierarchy/spaces/${spaceGroupId}`);

    const formattedAllSpaces = convertKeys.toCamel(allSpaces);
    this.spaces$.next(formattedAllSpaces as Space[]);

    this.loadingSpaces = false;
    return formattedAllSpaces;
  }

  async getContextList() {
    const spaces = await this.hierarchyService.getSpecificHierarchies(CONTEXT_HIERARCHY);
    this.contextList$.next(spaces as Space[]);
  }

  async getSite(siteId: string): Promise<SiteItem> {
    try {
      this.loadingSpaces = true;
      const site = await this.apiService.get(`sites/${siteId}`);
      this.loadingSpaces = false;
      return convertKeys.toCamel(site);
    } catch (err) {
      console.log('Could not load site.', err);
    }
  }
}
