import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { SiteItem } from '../model/site.model';
import { SpaceService } from './space.service';
import { BusinessRolesService } from './business-roles.service';
import { ContactSubscriptionService } from './contact-subscriptions.service';
import { UserRole } from '../model/business-role.model';
import { ContactSubscription, SubscriptionTable } from '../model/subscription-table.model';
import { Organization, SpaceGroup } from '../model/organization-space-group.model';

const ROLE_TO_SUB = ['DR_GENERAL_USER', 'DR_ENERGY_USER', 'IRISH_MARKET_USER'];
const TABLE_SUBSCRIPTIONS = ['NOTIFY_SMS', 'NOTIFY_PHONE', 'NOTIFY_EMAIL', 'OFFER_EMAIL', 'PERFORMANCE_EMAIL'];
enum COMMUNICATION_TYPES {
  sms = 'NOTIFY_SMS',
  voice = 'NOTIFY_PHONE',
  email = 'NOTIFY_EMAIL',
  offer = 'OFFER_EMAIL',
  performance = 'PERFORMANCE_EMAIL',
}

@Injectable()
export class SubscriptionTableService {
  loadingSites$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadingTable$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadingData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private accessibleSites$: Subject<{ accessibleSites: SiteItem[]; userId: string }> = new Subject<{
    accessibleSites: SiteItem[];
    userId: string;
  }>();
  subscriptionTableData$: BehaviorSubject<SubscriptionTable[]> = new BehaviorSubject<SubscriptionTable[]>([]);
  userRoles: UserRole[];

  constructor(
    private spaceService: SpaceService,
    private businessRolesService: BusinessRolesService,
    private contactSubscriptionService: ContactSubscriptionService,
  ) {
    this.businessRolesService.userRoles$.subscribe((userRoles) => {
      const { roles, userId } = userRoles;
      if (roles && userId) {
        this.getListOfAccessibleSites(roles, userId);
      }
    });

    combineLatest([this.contactSubscriptionService.contactSubscriptions$, this.accessibleSites$]).subscribe(
      ([subs, userSites]) => {
        const { subscriptions, userId: subsUserId } = subs;
        const { accessibleSites, userId: rolesUserId } = userSites;
        if (subscriptions && accessibleSites && subsUserId === rolesUserId) {
          this.subscriptionTableData$.next([]);
          this.buildSubscriptionTableData(accessibleSites, subscriptions);
        }
      },
    );

    combineLatest([
      this.businessRolesService.loading$,
      this.contactSubscriptionService.loading$,
      this.loadingSites$,
      this.loadingTable$,
    ]).subscribe(([loadingRoles, lodaingSubs, loadingSites, buildingTable]) => {
      this.loadingData$.next(loadingRoles || lodaingSubs || loadingSites || buildingTable);
    });
  }

  private async getListOfAccessibleSites(userRoles: UserRole[], userId: string) {
    this.userRoles = [...userRoles];
    this.loadingSites$.next(true);
    const entitiesWithRoles = userRoles
      .filter((role) => ROLE_TO_SUB.includes(role.businessRole))
      .map((role) => role.entityIds)
      .flat();

    // Initialize set of table sites
    const subscriptionTableSites = new Set<SiteItem>();

    const sitesWithRoles = this.getListOfEntities(entitiesWithRoles, 'Site') as SiteItem[];

    // Process sites
    sitesWithRoles.forEach((site) => subscriptionTableSites.add(site));

    const orgsWithRoles = this.getListOfEntities(entitiesWithRoles, 'Organization') as Organization[];

    let allFlattenMapOfSpaces = new Map();

    // Process organizations
    for (const org of orgsWithRoles) {
      const flattenMapOfSpaces = await this.spaceService.getFlattenedMapOfTypes(
        ['spacegroup', 'site'],
        'ORGANIZATION',
        org.id,
      );
      allFlattenMapOfSpaces = new Map([...flattenMapOfSpaces, ...allFlattenMapOfSpaces]);
      const orgSites = Array.from(flattenMapOfSpaces.values()).filter(
        (space) => space.space_type == 'Site' && space.type == 'Site',
      );
      orgSites.forEach((site) => subscriptionTableSites.add(site));
    }

    const spaceGroupsWhitRoles = this.getListOfEntities(entitiesWithRoles, 'SpaceGroup') as SpaceGroup[];
    // Process space groups
    for (const spaceGroup of spaceGroupsWhitRoles) {
      if (!allFlattenMapOfSpaces.has(spaceGroup.id)) {
        const spaceGroupHierarchy = await this.spaceService.getHierarchyBySpaceGroupId(spaceGroup.id);
        const spaceGroupSites = this.extractSitesFromHierarchy(spaceGroupHierarchy, 'Site');
        spaceGroupSites.forEach((site) => subscriptionTableSites.add(site));
      }
    }

    // Process subscriptions

    const allAccessibleSites = subscriptionTableSites.size > 0 ? Array.from(subscriptionTableSites) : [];
    this.loadingSites$.next(false);
    this.accessibleSites$.next({ accessibleSites: allAccessibleSites, userId });
  }

  getListOfEntities(
    entityListWithRoles: (SiteItem | SpaceGroup | Organization)[],
    type: string,
  ): (SiteItem | SpaceGroup | Organization)[] {
    return entityListWithRoles.filter((entity) => entity.spaceType === type);
  }

  extractSitesFromHierarchy(entityHierarchy: any, type: string): SiteItem[] {
    const list: SiteItem[] = [];
    if (!entityHierarchy || !entityHierarchy.children || entityHierarchy.children.length <= 0) {
      return list;
    }

    entityHierarchy.children.forEach((entity) => {
      if (entity.spaceType === type) {
        list.push(entity);
      }
      if (entity.children && entity.children.length > 0) {
        const listTypes = this.extractSitesFromHierarchy(entity, type);
        list.push(...listTypes);
      }
    });
    return list;
  }

  async buildSubscriptionTableData(accessibleSites: SiteItem[], subscriptions: ContactSubscription[]) {
    this.loadingTable$.next(true);
    const subscriptionsSitesIds = new Set(
      subscriptions
        .filter(
          ({ communicationName, entityType }) =>
            TABLE_SUBSCRIPTIONS.includes(communicationName) && entityType.toLowerCase() === 'site',
        )
        .map(({ entityId }) => entityId),
    );

    const accessibleSitesIds = new Set(accessibleSites.map((site) => site.id));
    for (const siteId of subscriptionsSitesIds) {
      if (!accessibleSitesIds.has(siteId)) {
        const site = await this.spaceService.getSite(siteId);
        accessibleSites.push(site);
      }
    }

    // Step 1: Initialize the site map with basic data
    const siteMap = this.initializeSiteMap(accessibleSites);

    // Step 2: Update the map with subscription details
    const notifySubscriptions = subscriptions.filter((sub) => TABLE_SUBSCRIPTIONS.includes(sub.communicationName));

    this.applySubscriptionsToSiteMap(siteMap, notifySubscriptions);

    // Step 3: Convert the map to an array, sort it, and prepare the data source
    const sortedSubscriptionTable = this.prepareSubscriptionTable(siteMap);

    // // Step 4: Set up the table data source and paginator
    this.subscriptionTableData$.next(sortedSubscriptionTable);
    this.loadingTable$.next(false);
  }

  private initializeSiteMap(finalListOfSetOfSites: SiteItem[]): Map<string, any> {
    const siteMap = new Map<string, any>();
    finalListOfSetOfSites.forEach((site: SiteItem) => {
      if (site) {
        siteMap.set(site.id, {
          tag: site.id,
          subscriptionId: null,
          name: site.displayLabel || site.name,
          sms: false,
          voice: false,
          email: false,
          offer: false,
          performance: false,
        });
      }
    });
    return siteMap;
  }

  private applySubscriptionsToSiteMap(siteMap: Map<string, any>, subscriptions: ContactSubscription[]): void {
    const communicationMap = {
      [COMMUNICATION_TYPES.email]: 'email',
      [COMMUNICATION_TYPES.voice]: 'voice',
      [COMMUNICATION_TYPES.sms]: 'sms',
      [COMMUNICATION_TYPES.offer]: 'offer',
      [COMMUNICATION_TYPES.performance]: 'performance',
    };

    subscriptions.forEach(({ entityId, entityType, communicationName, subscriptionId }: ContactSubscription) => {
      const matchingSite = siteMap.get(entityId);
      if (matchingSite && matchingSite.subscriptionId === null) {
        matchingSite.entityId = entityId;
        matchingSite.entityType = entityType;
        const key = communicationMap[communicationName];
        if (key) {
          matchingSite[`${key}SubscriptionId`] = subscriptionId;
          matchingSite[key] = true;
        }
      }
    });
  }

  private prepareSubscriptionTable(siteMap: Map<string, any>): SubscriptionTable[] {
    const subscriptionTable = Array.from(siteMap.values());
    return this.sortSubscriptionTable(subscriptionTable);
  }

  sortSubscriptionTable(newSub: SubscriptionTable[]): SubscriptionTable[] {
    const withSubscriptionId = newSub.filter(
      (sub) =>
        sub.emailSubscriptionId ||
        sub.voiceSubscriptionId ||
        sub.smsSubscriptionId ||
        sub.offerSubscriptionId ||
        sub.performanceSubscriptionId,
    );
    const withoutSubscriptionId = newSub.filter(
      (sub) =>
        !(
          sub.emailSubscriptionId ||
          sub.voiceSubscriptionId ||
          sub.smsSubscriptionId ||
          sub.offerSubscriptionId ||
          sub.performanceSubscriptionId
        ),
    );

    withSubscriptionId.sort((a, b) => a.name.localeCompare(b.name));
    withoutSubscriptionId.sort((a, b) => a.name.localeCompare(b.name));

    return (newSub = [...withSubscriptionId, ...withoutSubscriptionId]);
  }
}
