import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class ItsService {
  constructor(private apiService: ApiService) {}

  async hasEcrmMapping(flexIds: string[]) {
    try {
      return await this.apiService.post(`mapping/ecrm`, { flexIds: flexIds });
    } catch (err) {
      console.log('Failed to get ITS mappings for flexIds', err);
      throw err;
    }
  }
}
