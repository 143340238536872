import { Injectable } from '@angular/core';
import { Measurement } from '../model/measurement.model';
import { ApiService } from './api.service';
import * as convertKeys from 'convert-keys';

@Injectable()
export class MeasurementService {
  measurements: Measurement[];

  constructor(private apiService: ApiService) {
    this.measurements = [];
  }

  async setMeasurements() {
    const { data: measurementsObject } = await this.apiService.get('measurements');
    if (this.measurements && Array.isArray(this.measurements)) {
      while (this.measurements.length > 0) {
        this.measurements.pop();
      }
    }

    if (measurementsObject) {
      Object.keys(measurementsObject).forEach((key) => {
        this.measurements.push(convertKeys.toCamel<Measurement>(measurementsObject[key]));
      });
    }
  }
}
